.lego-part {
  background-color: white;
  margin: 2px;
  color: black;
  padding: 0;
  text-align: center;
  .part-label {
    font-size: 70%;
    .actionable {
      cursor: pointer;
    }
  }
  .part-detail {

  }
}
