.item-menu {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1px;
  padding-right: 4px;
  & > .fa {
    background-color: #fff;
    border: solid 1px #aaa;
    padding: 0 2px;
    cursor: pointer;
  }
  .dropdown-menu {
    font-size: 10px;
    padding: 2px 0;
    min-width: 3rem;
    .dropdown-item {
      padding: 2px 4px;
      &.disabled {
        background-color: #ccc;
      }
    }
    .dropdown-divider {
      margin: 0;
      border-color: #333;
    }
  }
}
