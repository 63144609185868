.ReactTable .rt-th , .ReactTable .rt-td {
  padding: 3px 4px;
  overflow: visible;
  i.disabled {
    color: #600;
  }
}
.ReactTable .rt-th.left {
  text-align: left;
}
.ReactTable .rt-td.centered {
  text-align: center;
}
.ReactTable .rt-td.right {
  text-align: right;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  cursor: n-resize ! important;
}
.actions a , .actions span {
  color: #333;
  padding: 1px 3px;
  margin: 0 2px;
  border: solid 1px #ccc;
  opacity: 0.2;
}
.actions a:hover , .actions span:hover {
  color: #000;
  opacity: 1.0;
  background-color: #fff;
}
.ReactTable.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) .actions a ,
.ReactTable.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) .actions span {
  opacity: 0.5;
}
.ReactTable.-highlight.selectable .rt-tbody .rt-tr {
  cursor: zoom-in;
}
.ReactTable.-highlight .rt-tbody .rt-tr.selected {
  font-weight: bold;
  background-color: #669;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #447;
}
.ReactTable.-highlight .rt-tbody .rt-tr.selected.locked {
  background-color: #f2e5ff;
}
.ReactTable.-highlight .rt-tbody .rt-tr.selected.locked:hover {
  background-color: #e5ccff;
}
.ReactTable.-highlight .rt-tbody .rt-tr.selected:hover {
  background-color: #77a;
}
.ReactTable.-highlight .rt-tbody .rt-tr.locked {
  background-color: #fee;
  &:hover:not(.-padRow) {
    background-color: #edd;
  }
}
.ReactTable.-highlight .rt-tbody .rt-tr.locked:hover {
  background-color: #fcc;
}
